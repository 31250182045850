<template>
  <v-row v-if="withId && withDate" no-gutters class="ref-id-selector">
    <v-col cols="12">
      <v-menu
        v-model="createdMenu"
        :close-on-content-click="false"
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            label="Created"
            hide-details
            outlined
            dense
            persistent-hint
            append-icon="mdi-calendar"
            readonly
            class="rounded-pill"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="createdDate"
          no-title
          @input="createdMenu = false, GENERATE_REFID()"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" class="pt-2">
      <v-text-field
        v-model="ref_increment"
        label="ID"
        block
        outlined
        dense
        hide-details
        :color="$vuetify.theme.dark ? 'grey' : 'primary'"
        class="accent rounded-pill"
        autocomplete="off"
        @keyup="GENERATE_REFID()"
      />
    </v-col>
  </v-row>
  <v-row v-else-if="withId" no-gutters class="ref-id-selector">
    <v-col cols="12" class="pr-1">
      <v-text-field
        v-model="ref_increment"
        label="ID"
        block
        outlined
        dense
        hide-details
        :color="$vuetify.theme.dark ? 'grey' : 'primary'"
        class="accent"
        autocomplete="off"
        @keyup="GENERATE_REFID()"
      />
    </v-col>
  </v-row>
  <v-row v-else no-gutters class="ref-id-selector">
    <v-col cols="3" class="pr-1">
      <v-select
        v-model="ref_source"
        label="Order Source"
        :items="[{ id: '', name: 'No Source' }].concat($store.state.trxSource)"
        item-value="id"
        item-text="name"
        block
        outlined
        dense
        hide-details
        :color="$vuetify.theme.dark ? 'grey' : 'primary'"
        class="accent"
        autocomplete="off"
        @change="GENERATE_REFID()"
      />
    </v-col>
    <v-col cols="2" class="pr-1">
      <v-select
        v-model.number="ref_admin"
        label="Admin"
        :items="[{ id: 0, name: 'No Admin' }].concat($store.state.user_admin.filter(r => ref_stokist ? (parseInt(r.master) === ref_stokist) : true))"
        :item-text="(v) => {
          return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
        }"
        item-value="id"
        block
        outlined
        dense
        hide-details
        :color="$vuetify.theme.dark ? 'grey' : 'primary'"
        class="accent"
        autocomplete="off"
        @change="GENERATE_REFID()"
      />
    </v-col>
    <v-col cols="3" class="pr-1">
      <v-select
        v-model.number="ref_stokist"
        :disabled="parseInt($store.state.user.isstockist) ? true : false"
        label="Stokist"
        :items="$store.state.stokist"
        item-value="id"
        item-text="user_name"
        block
        outlined
        dense
        hide-details
        :color="$vuetify.theme.dark ? 'grey' : 'primary'"
        class="accent"
        autocomplete="off"
        @change="GENERATE_REFID()"
      />
    </v-col>
    <v-col cols="2">
      <v-menu
        v-model="createdMenu"
        :close-on-content-click="false"
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            label="Created"
            hide-details
            outlined
            dense
            persistent-hint
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="createdDate"
          no-title
          @input="createdMenu = false, GENERATE_REFID()"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2" class="pl-1">
      <v-text-field
        v-model="ref_increment"
        label="ID"
        block
        outlined
        dense
        hide-details
        :color="$vuetify.theme.dark ? 'grey' : 'primary'"
        class="accent"
        autocomplete="off"
        @keyup="GENERATE_REFID()"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    withDate: {
      type: Boolean,
      default: false
    },
    withId: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    ref_source: '',
    ref_admin: 0,
    ref_stokist: 0,
    ref_increment: '',
    createdMenu: false,
    createdDate: vm.$localDT(new Date(), 'datedefault')
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.createdDate)
    }
  },
  watch: {
    value () {
      this.REF_PARSER()
    }
  },
  mounted () {
    this.REF_PARSER()
  },
  methods: {
    GENERATE_REFID () {
      const result = this.ref_source + '/' + ('ADM' + (parseInt(this.ref_admin) || '0') + '/') + ('EB' + parseInt(this.ref_stokist)) + '/' + this.computedDateFormatted + '/' + this.ref_increment
      this.$emit('input', result)
    },
    // LAST_REFID_GET () {
    //   this.$store.dispatch('LAST_REFID_GET', '/:source/:uid/:user/:dt')
    //     .then((res) => {
    //       console.log(res)
    //     })
    // },
    REF_PARSER () {
      const refidSplit = this.value.split('/')
      if (refidSplit.length === 5) {
        this.ref_source = refidSplit[0] || ''
        this.ref_admin = parseInt(refidSplit[1].replace('ADM', '')) || 0
        this.ref_stokist = parseInt(refidSplit[2].replace('EB', ''))
        this.ref_increment = refidSplit[4]
        const d = refidSplit[3]
        const dt = d.substr(4, 2) + '-' + d.substr(2, 2) + '-' + d.substr(0, 2)
        this.createdDate = this.$localDT('20' + dt, 'datedefault')
      } else if (refidSplit.length === 6) {
        this.ref_source = (refidSplit[0] || '') + '/' + refidSplit[1]
        this.ref_admin = parseInt(refidSplit[2].replace('ADM', '')) || 0
        this.ref_stokist = parseInt(refidSplit[3].replace('EB', ''))
        this.ref_increment = refidSplit[5]
        const d = refidSplit[4]
        const dt = d.substr(4, 2) + '-' + d.substr(2, 2) + '-' + d.substr(0, 2)
        this.createdDate = this.$localDT('20' + dt, 'datedefault')
      }
    },
    GENINCREMENT (n) {
      n = n + 1
      if (n < 10) {
        return '000' + n
      } else if (n < 100) {
        return '00' + n
      } else if (n < 1000) {
        return '0' + n
      } else {
        return n
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const y = year ? year.substr(2, 2) : 'XX'
      return `${day}${month}${y}`
    }
  }
}
</script>

<style lang="scss">
.ref-id-selector {
  .v-select,
  .v-text-field {
    .v-select__selections {
      >input {
        width: 1px;
        height: 1px;
        background: transparent;
        overflow: hidden;
        visibility: hidden!important;
      }
    }
  }
}
</style>
